<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 网络错误 -->
		<el-row v-if="value==1">
			<el-row>
				<el-image src="/index/error.png" class="error-img"></el-image>
			</el-row>
			<el-row class="error-message">
				NetWork-BAD
			</el-row>
		</el-row>
		
		<!-- 没有数据 -->
		<el-row v-else-if="value==2">
			<el-row>
				<el-image src="/index/nodata.png" class="error-img"></el-image>
			</el-row>
			<el-row class="error-message">
				Empty data
			</el-row>
		</el-row>
		
		<!-- 404 -->
		<el-row v-else>
			<el-row>
				<el-image src="/index/404.png" class="error-img"></el-image>
			</el-row>
			<el-row class="error-message">
				Unable to access server 404
			</el-row>
		</el-row>
			
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
			
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
// 头部组件
import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
// 菜单导航
import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
// 底部导航
import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
	export default
	{
		data()
		{
			return{
				// 导航值
				navigationvalue:false,
				
				value:1,
			}
		},
		components:
		{
			moveHeadLogo,
			moveMeauDialog,
			moveBottomNavigation,
		},
		created()
		{
			console.log(this.$route.query.item)
			this.value=this.$route.query.item;
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			}
		}
	}
</script>

<style scoped>
	.error-img{
		width: 200px;
		height: 200px;
		margin-top: 100px;
	}
	.error-message{
		margin-top: 30px;
		font-size: 20px;
		color: #3B519D;
		margin-bottom: 50px;
	}
</style>